<template>
	<div class="FM_page-login">
		<div class="container" v-if="spinner">
			<div class="row">
				<div class="col">
					<div class="d-flex justify-content-center" style="padding: 30px 0;">
						<div class="spinner-border text-light" role="status">
							<span class="sr-only">Loading...</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container" v-if="!spinner">
			<div class="row">
				<div class="col-lg-4 offset-lg-4 col-xl-4 offset-xl-4">
					<h1 class="FM_logo-login text-center">
						<router-link to="/">
							<img src="~@/assets/logo/logo-h.png" class="img-logo" width="100%">
						</router-link>
					</h1>
					<ValidationObserver v-slot="{ handleSubmit }">
						<form class="FM_loginBox" autocomplete="off" @submit.prevent="handleSubmit(submit)">
							<h2 class="text-title">{{ $t('__register') }}</h2>
							<div class="alert alert-danger" role="alert" v-show="formStatus.success === 0">
								{{ formStatus.errorMsg }}
							</div>
							<div class="form-group">
								<input type="text" v-model="form.email" class="form-control FM_inputStyle-circle" disabled :placeholder="$t('__email')">
							</div>
							<div class="form-group">
								<ValidationProvider vid="password" rules="required|min:8" :name="$t('__fieldPassword')" v-slot="{ errors }">
									<input type="password" v-model="form.password" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner || formStatus.success === 0" :placeholder="$t('__password')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<div class="form-group">
								<ValidationProvider rules="required|confirmed:password" :name="$t('__fieldConfirm')" v-slot="{ errors }">
									<input type="password" v-model="form.confirm" class="form-control FM_inputStyle-circle" :class="{ 'invalid': errors.length > 0 }" :disabled="formStatus.spinner || formStatus.success === 0" :placeholder="$t('__repeatPassword')">
									<span class="text-danger FM_input-error">{{ errors[0] }}</span>
								</ValidationProvider>
							</div>
							<button type="submit" class="btn btn-success btn-block FM_btn FM_submit-account" style="margin-bottom: 0;" :disabled="formStatus.spinner || formStatus.success === 0">
								<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-show="formStatus.spinner"></span>
								{{ $t('__register') }}
							</button>
						</form>
					</ValidationObserver>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { Ajax } from "@/api/index.js"
import { API } from "@/api/api.js"
import { app } from "@/main.js"
import { urlReplace } from "@/utils/index.js"
import { setStorage, getStorage, removeAllStorage } from "@/utils/storage.js"
import { mapState, mapActions, mapMutations } from 'vuex'
import { extend } from 'vee-validate'
import { required, confirmed, min } from 'vee-validate/dist/rules'
import CN from 'vee-validate/dist/locale/zh_CN.json'
import { localize } from 'vee-validate'
import VueCookies from 'vue-cookies'

export default {
	name: "Verify",
	data(){
		return{
			code: '',
			spinner: true,
			form: {
				email: '',
				password: '',
				confirm: ''
			},
			formStatus: {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not logined
				errorMsg: ''
			}
		}
	},
	computed: {
		...mapState(['Login'])
	},
	created() {
		extend('min', {
			...min,
			message: 'The {_field_} field must have at least {length} characters'
		});

		extend('required', required);
		extend('confirmed', confirmed);

		this.code = this.$route.params.code;
		this.getEmail();
	},
	methods: {
		...mapMutations(['setIsLogin']),
		...mapActions(['login', 'getStatus']),
		resetStatus() {
			this.formStatus = {
				spinner: false,
				success: null, // 0:fail / 1:success / null: not logined
				errorMsg: ''
			}
		},
		getEmail() {
			this.spinner = true;

			Ajax(
				API.getInvitation.method,
				urlReplace(API.getInvitation.url, { code: this.code })
			).then(async (result) => {
				this.spinner = false;

				if(result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
					return ;
				}

				this.form.email = result.data.email;
			})
		},
		submit() {
			this.resetStatus();
			this.$Progress.start();
			this.formStatus.spinner = true;

			let _data = {
				password: this.form.password
			};


			Ajax(
				API.confirmInvitation.method,
				urlReplace(API.confirmInvitation.url, { code: this.code }),
				_data
			).then(async (result) => {
				this.formStatus.spinner = false;

				if(result.success !== 1) {
					this.formStatus.success = result.success;
					this.formStatus.errorMsg = result.error_msg;
					return ;
				}

				await VueCookies.set('account', result.data.account, '30d', null);
				await VueCookies.set('service-token', result.data.service_token, '30d', null);

				await this.$router.push('/invest/list');
			})
		}
	},
}
</script>

<style scoped>

</style>